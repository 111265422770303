<script>
import { mapGetters } from "vuex"
import store from "@/store/index.js"
export default {
  props:{
    catalog: {
      type: String,
    },
  },
  data() {
    return {
      maintenanceMessage: "",
      catalogMessage: "",
    }
  },
  computed: {
    ...mapGetters("global", ["getCampaignAcronym"]),
  },
  watch: {
    catalog (val) {
      const appName = "arm.fieldcatalog."+val
      store.dispatch("global/checkMaintenanceMessage", appName).then( response => {
        if (response.data.message !== "") {
          this.catalogMessage = response.data.message
        } else {
          this.catalogMessage = ""
        }
      })
    },
  },
  mounted() {
    const self = this
    store.dispatch("global/checkMaintenanceMessage", "arm.fieldcatalog").then( response => {
      if (response.data.message !== "") {
        self.maintenanceMessage = message
      } else {
        self.maintenanceMessage = ""
      }
    })
    console.log(this.campaign)
  }, 
}
</script>

<template>
  <b-row>
    <b-col md="12">
      <!-- Maintenance Message -->
      <b-alert 
        v-if="maintenanceMessage" 
        id="maintenanceMessage" 
        class="text-center border-0 rounded-0 my-2 maintenanceMessage" 
        dismissible 
        show
      >
        {{ maintenanceMessage }}
      </b-alert>
      <!-- Catalog Specific Message -->
      <b-alert
        v-if="catalogMessage"
        id="catalogMessage" 
        class="text-center border-0 rounded-0 my-2 maintenanceMessage" 
        dismissible 
        show
      >
        {{ catalogMessage }}
      </b-alert>
    </b-col>
  </b-row>
</template>

<style>
.maintenanceMessage {
    font-size: 1.2em;
    font-weight: bold;
    color: #a94442 !important;
    background-color: #f2dede !important;
}
</style>
