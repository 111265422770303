<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      weatherLink: null,
      weatherLabel: null,
    }
  },
  computed: {
    ...mapGetters("global", ["getCatalogId"]),
  },
  mounted() {
    this.getWeatherWidgetData()
    this.setupWeatherWidget()
  },
  beforeDestroy() {
    this.clearWeatherWidget()
  },
  methods: {
    setupWeatherWidget() {
      !function(d,s,id) {
        var js,fjs=d.getElementsByTagName(s)[0];if (!d.getElementById(id)) {
          js=d.createElement(s);js.id=id;js.src="https://weatherwidget.io/js/widget.min.js";fjs.parentNode.insertBefore(js,fjs) 
        } 
      }(document,"script","weatherwidget-io-js")
    },
    clearWeatherWidget() {
      const widget = document.getElementById("weatherwidget-io-js")
      if (widget) {
        widget.remove()
      }
    },
    getWeatherWidgetData() {
      this.$store.dispatch("global/getWeatherWidgetData", this.getCatalogId).then(response => {
        this.weatherLink = response?.data?.data?.allFieldCatalogCatalogs?.nodes?.[0]?.weatherLink
        this.weatherLabel = response?.data?.data?.allFieldCatalogCatalogs?.nodes?.[0]?.weatherLabel
      })
    },
  },
}
</script>

<template>
  <div v-if="weatherLink && weatherLabel">
    <a
      class="weatherwidget-io"
      :href="weatherLink"
      :data-label_1="weatherLabel"
      data-label_2="WEATHER"
      data-font="Verdana"
      data-theme="pure"
    >
      WEATHER
    </a>
  </div>
</template>

<style scoped>
</style>
