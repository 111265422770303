import axios from "axios"
import moment from "moment"
import Config from "/config.js"

const global = {
  namespaced: true,
  state: {
    destination: null,
    processing: false,
    allPeople: [],
    campaign: {
      catalogId: null,
      campaignAcronym: null,
      campaignSiteCode: null,
      campaignCode: null,
    },
    viewsAuthRequired: [
      "Contacts",
      "Calendar",
      "ListReports",
      "Report",
      "ReportEdit",
      "MaintenanceReports",
      "MaintenanceReportTypes",
    ],
    fieldSortMap: {
      "fieldCatalog": {
        "reportId": "REPORT_ID",
        "submitter": "PERSON_BY_PERSON_ID__FULL_NAME",
        "reportDate": "REPORT_DATE",
      },
      "cm": {
        "reportId": "reportId",
        "reportedOn": "reportedOn",
        "facilityCode": "reportFacilityCode",
        "instrument": "instrumentCode",
      },
      "pm": {
        "reportId": "reportId",
        "reportedOn": "reportedOn",
        "facilityCode": "reportFacilityCode",
        "instrument": "reportInstrumentClass",
      },
    },
  },
  getters: {
    getDestination: state => {
      return state.destination
    },
    getProcessing: state => {
      return state.processing
    },
    getCatalogId: state => {
      return state.campaign?.catalogId
    },
    getCampaignAcronym: state => {
      const campaignAcronym = state.campaign?.campaignAcronym
      return campaignAcronym ? campaignAcronym.toLowerCase() : campaignAcronym
    },
    getCampaignSiteCode: state => {
      return state.campaign?.campaignSiteCode
    },
    getCampaignCode: state => {
      return state.campaign?.campaignCode
    },
    getChartOptions: state => {
      return state.chartOptions
    },
    getChartFields: state => {
      return state.chartFields
    },
    getFieldSortMap: state => {
      return state.fieldSortMap
    },
  },
  mutations: {
    setDestination: (state, payload) => {
      state.destination = payload
    },
    setProcessing: (state, value) => {
      state.processing = value
    },
    setCampaign: (state, payload) => {
      state.campaign = payload
    },
    clearCampaign: (state) => {
      state.campaign = {
        catalogId: null,
        campaignAcronym: null,
        campaignSiteCode: null,
      }
    },
  },
  actions: {
    setDestination: ({commit}, payload) => {
      commit("setDestination", payload)
    },
    setProcessing: ({commit}, value) => {
      commit("setProcessing", value)
    },
    getCampaigns: ({commit, state, rootState}) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogs {
              nodes {
                name
                fcAcronym
                catalogId
                catalogCode
                startDate
                endDate
                siteCode
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getCampaignByAcronym: ({commit, state, rootState}, acronym) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogs(
              filter: {
                fcAcronym: {
                  likeInsensitive: "${acronym}"
                }
              }
            ) {
              nodes {
                name
                fcAcronym
                catalogId
                catalogCode
                startDate
                endDate
                siteCode
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getWeatherWidgetData: ({commit, state, rootState}, catalogId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogs(
              condition: {
                catalogId: ${catalogId}
              }
            ) {
              nodes {
                weatherLabel
                weatherLink
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getCampaignOverview: ({commit, state, rootState}, catalogId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogs(condition: {catalogId: ${catalogId}}) {
              nodes {
                overview
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    searchPeople: ({commit, state, rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allPeople(
              filter: {
                tsvSearch: {
                  matches: "*${payload}*"
                },
                statusCode: {
                  in: ["A", "F"]
                }
              },
            ) {
              nodes {
                personId
                nameLast
                nameFirst
                email
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getContacts: ({commit, state, rootState}, catalogId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogContacts(
              condition: {
                catalogId: ${catalogId}
              }
            ) {
              nodes {
                catalogId
                contactType
                contactSubtype
                contactOrder
                personId
                personByPersonId {
                  nameFirst
                  nameLast
                  affiliation
                  email
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getCharts: ({commit, state, rootState}, catalogId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogCharts(
              condition: {
                catalogId: ${catalogId}
              }
            ) {
              edges {
                node {
                  chartInputSchema
                  chartInputType
                  chartCode
                  chartLabel
                  chartUrl
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
    },
    getChart: ({commit, state, rootState}, chartCode) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogCharts(
              condition: {
                chartCode: "${chartCode}"
              }
            ) {
              edges {
                node {
                  chartInputSchema
                  chartInputType
                  chartLabel
                  chartUrl
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
    },
    setCampaign ({commit}, campaign) {
      commit("setCampaign", campaign)
    },
    clearCampaign ({commit}) {
      commit("clearCampaign")
    },
    checkMaintenanceMessage({commit, state, rootState}, appName) {
      return axios.get(`${Config.archiveServices.url}/MaintenanceCheck?app=${appName}`)
    },
  },
}

export default global
