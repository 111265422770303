import Vue from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import { EventBus } from "@/event-bus.js"
import AdcWebComponents from "@datatools/vue2-components-lib"
import VueMoment from "vue-moment"

// Bootstrap Vue
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import "../node_modules/@datatools/vue2-components-lib/src/scss/Bootstrap.scss"

Vue.use(BootstrapVue, {
  breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl", "xxxxl"],
})

Vue.use(BootstrapVueIcons)
Vue.use(AdcWebComponents)
Vue.use(VueMoment)

router.beforeEach((to, from, next) => {
  if (typeof(to) != "undefined") {
    if (to.path == "/logout") {
      store.dispatch("SessionManager/logout")
    } else if (to.path === "/") {
      // Clear campaign info if going to dashboard
      store.dispatch("global/clearCampaign")
      next()
    } else {
      store.dispatch("SessionManager/extendSession")
      
      // Check if route requires authentication
      const loggedIn = store.state.AuthService.loggedIn
      const requiresAuth = store.state.global.viewsAuthRequired.includes(to.name)
      if (!loggedIn && requiresAuth) {
        if (!from.name) {
          router.push({
            path: `/`,
          })
        }
        store.dispatch("global/setDestination", to)
      } else {
        // Get campaign info if necessary
        const campaign = store.state.global.campaign
        if (!campaign || !campaign.catalogId) {
          const acronym = to.path.split("/")[1]
          store.dispatch("global/getCampaignByAcronym", acronym).then(response => {
            const result = response?.data?.data?.allFieldCatalogCatalogs?.nodes?.[0]
            if (result) {
              const payload = {
                catalogId: result.catalogId,
                campaignAcronym: acronym,
                campaignSiteCode: result.siteCode,
              }
              store.dispatch("global/setCampaign", payload).then(() => next())
            }
          })
        } else {
          next()
        }
      }
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
