<script>
import { mapGetters } from "vuex"
import Back from "@/components/Back"
import ResultsContainer from "@/components/ResultsContainer"
import Paginator from "@/components/Paginator"
import Spinner from "@/components/Spinner"

export default {
  components: {
    Back,
    ResultsContainer,
    Paginator,
    Spinner,
  },
  data: () => ({
    loading: false,
    reportTypeDesc: null,
    filter: "",
    availableReports: [],
    orderBy: null,
    pageNumber: 1,
    pageSize: 10,
    totalRows: 0,
    availableReportsFields: [
      { key: "reportId", label: "ID", sortable: true },
      { key: "shortDescription", label: "Short Description", sortable: true },
      { key: "submitter", label: "Submitter", sortable: true },
      { key: "reportDate", label: "Report Date", sortable: true },
      { key: "updated", label: "Report Updated" },
      { key: "action", label: "Action" },
    ],
  }),
  computed: {
    ...mapGetters("global", [
      "getCatalogId",
      "getCampaignAcronym",
      "getFieldSortMap",
    ]),
    reportType() {
      return this.$route?.params?.reportType
    },
    route() {
      return this.$route.params.reportType?.toString()
    },
  },
  watch: {
    route() {
      this.$emit("formContextUpdated")
    },
    pageNumber() {
      this.getReports()
    },
    pageSize() {
      this.getReports()
    },
    filter() {
      if (this.pageNumber !== 1) {
        this.pageNumber = 1
      } else {
        this.getReports()
      }
    },
  },
  mounted() {
    this.getReports()
  },
  methods: {
    goToReports() {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/reports`,
      })
    },
    getShortDescription(node) {
      if (node.shortDescription && node.shortDescription !== "undefined") {
        return node.shortDescription.length > 50? node.shortDescription.substring(0, 50) + "..." : node.shortDescription
      } else if (node.planDetails && node.planDetails !== "undefined") {
        return node.planDetails.length > 50? node.planDetails.substring(0, 50) + "..." : node.planDetails
      } else if (node.fieldCatalogReportAdditionalFieldByReportId && node.fieldCatalogReportAdditionalFieldByReportId.weatherNotes && node.fieldCatalogReportAdditionalFieldByReportId.weatherNotes !== "undefined") {
        return node.fieldCatalogReportAdditionalFieldByReportId.weatherNotes.length > 50? node.fieldCatalogReportAdditionalFieldByReportId.weatherNotes.substring(0, 50) + "..." : node.fieldCatalogReportAdditionalFieldByReportId.weatherNotes
      } else {
        return "No short description provided"
      }
    },
    getReports() {
      this.loading = true
      const payload = {
        catalogId: this.getCatalogId,
        reportType: this.reportType,
        orderBy: this.orderBy,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        filter: this.filter,
      }
      this.$store.dispatch("reports/getReports", payload).then(response => {
        this.reportTypeDesc = response?.data?.data?.allFieldCatalogReportTypes?.nodes?.[0].reportTypeDesc
        const nodes = response?.data?.data?.allFieldCatalogReportTypes?.nodes?.[0]?.fieldCatalogCatalogReportsByReportTypeId?.nodes
        if (nodes && Array.isArray(nodes) && nodes.length > 0) {
          this.availableReports = nodes.map(node => {
            return {
              key: node.reportId,
              reportId: node.reportId,
              submitter: `${node.personByPersonId.nameFirst} ${node.personByPersonId.nameLast}`,
              shortDescription: this.getShortDescription(node),
              reportDate: this.$moment.utc(node.reportDate).format("YYYY-MM-DD HH:mm:ss"),
              updated: node.reportUpdated? this.$moment.utc(node.reportUpdated.datetime).format("YYYY-MM-DD HH:mm:ss") : "",
              action: [
                {
                  key: "editReport",
                  label: "View/Edit Report",
                },
              ],
            }
          })
          this.totalRows = response?.data?.data?.allFieldCatalogReportTypes?.nodes?.[0]?.fieldCatalogCatalogReportsByReportTypeId?.totalCount
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(error => {
        console.error(error)
        this.loading = false
      })
    },
    sortChanged(sort) {
      if (sort?.sortBy) {
        const sortBy = this.getFieldSortMap["fieldCatalog"][sort.sortBy]
        const sortDir = sort.sortDesc ? "DESC" : "ASC"
        this.orderBy = `${sortBy}_${sortDir}`
        this.getReports()
      }
    },
    editReport(reportId) {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/report/${reportId}`,
      })
    },
  },
}
</script>

<template>
  <div>
    <Back />
    <b-container>
      <b-row>
        <b-col cols="8">
          <h2
            class="field-reports font-weight-bold pointer"
            @click="goToReports"
          >
            Field Reports
          </h2>
          <h1 class="font-weight-bold">
            {{ reportTypeDesc }}
          </h1>
        </b-col>
        <b-col cols="4">
          <b-input
            v-model="filter"
            class="rounded-0"
            placeholder="Filter reports"
            small
          />
        </b-col>
      </b-row>
      <template v-if="availableReports.length > 0">
        <ResultsContainer
          :rows="availableReports"
          :fields="availableReportsFields"
          hide-totals
          @sortChanged="sortChanged"
          @editReport="editReport"
        />
        <Paginator
          v-model="pageNumber"
          :page-size="pageSize"
          :total-rows="totalRows"
          @updatePageSize="pageSize = $event"
        />
      </template>
      <Spinner
        v-else-if="loading"
        text="Loading reports..."
      />
      <b-card
        v-else
        class="primary-background-light border-0 text-center mt-2"
      >
        <b-card-title class="no-results">
          No reports found.
        </b-card-title>
      </b-card>
    </b-container>
  </div>
</template>

<style scoped>
.no-results {
  font-family: "League Gothic";
  font-size: 2em;
}
</style>
