<script>
import { mapGetters, mapState } from "vuex"
import Spinner from "@/components/Spinner"
import Stats from "@/components/Stats"
import Navigation from "@/components/Navigation"
import AskUs from "@/components/AskUs"
import MaintenanceMessage from "@/components/MaintenanceMessage.vue"

export default {
  components: {
    Spinner,
    Stats,
    Navigation,
    AskUs,
    MaintenanceMessage,
  },
  data: () => ({
    refreshKey: 0,
  }),
  computed: {
    ...mapGetters("global", [
      "getProcessing",
      "getCampaignAcronym",
      "getDestination",
    ]),
    ...mapGetters("AuthService", ["getLoggedIn"]),
    ...mapState("SessionManager", ["expiration"]),
    ...mapState("global", ["viewsAuthRequired"]),
    processing: {
      get() {
        return this.getProcessing
      },
      set(value) {
        this.$store.dispatch("global/setProcessing", value)
      },
    },
    miniNavigation() {
      return this.$route.path === "/"
    },
    headerTitle() {
      return this.getCampaignAcronym ?
        `${this.getCampaignAcronym.toUpperCase()} - ARM Field Campaigns` :
        "ARM Field Campaigns"
    },
  },
  watch: {
    getLoggedIn(value) {
      if (!value) {
        const currentPathName = this.$route.name
        const requiresAuth = this.viewsAuthRequired.includes(currentPathName)
        if (requiresAuth) {
          this.$router.push({
            path: `/${this.getCampaignAcronym}/overview`,
          })
        }
      }
    },
    expiration(value) {
      if (value && this.getDestination) {
        this.$router.push(this.getDestination)
        this.$store.dispatch("global/setDestination", null)
      }
    },
    getDestination() {
      this.checkDestination()
    },
  },
  mounted() {
    if (process.env.PACKAGE_VERSION !== this.$store.getters["App/appVersion"]) {
      //this.$store.dispatch("App/refreshVersion")
    }

    this.checkDestination()
  },
  methods: {
    checkDestination() {
      if (this.getDestination) {
        this.$refs.appHeader.triggerLogin = true
      }
    },
  },
}
</script>

<template>
  <div id="app">
    <b-overlay
      :show="processing"
      no-center
    >
      <template #overlay>
        <Spinner
          text="Processing request..."
          class="processing-spinner position-fixed"
        />
      </template>

      <app-header
        ref="appHeader"
        :title="headerTitle"
        :hideIndicators="true"
      />
      <MaintenanceMessage :catalog="getCampaignAcronym" />
      <Stats />
      <Navigation :mini="miniNavigation" />

      <template v-if="$route.name === 'Maps'">
        <!-- Removing b-container to support rendering Maps view correctly in Safari -->
        <router-view
          id="content"
          :key="refreshKey"
          @formContextUpdated="refreshKey++"
        />
      </template>
      <template v-else>
        <b-container
          id="content"
          fluid
        >
          <router-view
            :key="refreshKey"
            @formContextUpdated="refreshKey++"
          />
        </b-container>
      </template>

      <app-footer />
    </b-overlay>
    <AskUs />
  </div>
</template>

<style>
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_ssv/ProximaNova-Regular.otf");
}

@font-face {
  font-family: "League Gothic";
  src: url("./assets/fonts/League-Gothic/LeagueGothic-Regular.otf");
}

html, html > body {
  font-family: "Proxima Nova";
}

#content {
  min-height: calc(100vh - 85px - 22px - 40px - 112px);
  background-color: #fff;
  background-image: linear-gradient(0deg ,hsla(0,0%,100%,.2), #fff), url(assets/images/backgrounds/bg.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  font-size: 12pt;
  padding: 0;
}

.processing-spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pointer:hover {
  cursor: pointer;
}

label {
  margin-bottom: 0 !important;
}

.field-reports {
  color: #194173 !important;
}

.foreground-white {
  color: white;
}

.foreground-blue {
  color: #194173 !important;
}

.background-blue {
  background-color: #194173 !important;
}

.background-green {
  background-color: #00bd70 !important;
}

.background-light {
  background-color: #8abedc !important;
}

.background-lighter {
  background-color: #627594 !important;
}

.background-grey {
  background-color: #ebebeb !important
}

/* Gets rid of the blue boxes chrome puts on everything that has focus */
:focus, .focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
