import axios from "axios"
import moment from "moment"
import Config from "/config.js"

const reports = {
  namespaced: true,
  actions: {
    getReportInfo: ({commit, state, rootState}, reportType) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogReportTypes(
              condition: {
                reportTypeName: "${reportType}"
              }
            ) {
              nodes {
                reportTypeId
                reportTypeName
                reportTypeDesc
                reportSchema
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    createCatalogReport: ({commit, state, rootState}, catalogReport) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createFieldCatalogCatalogReport(
                input: {
                  fieldCatalogCatalogReport: {
                    catalogId: ${catalogReport.catalogId},
                    personId: ${catalogReport.personId},
                    reportTypeId: ${catalogReport.reportTypeId},
                    planDetails: """${catalogReport.planDetails}""",
                    shortDescription: """${catalogReport.shortDescription}""",
                    reportDate: """${catalogReport.reportDate}""",
                  },
                }
              ) {
                fieldCatalogCatalogReport {
                  reportId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    createCatalogAdditionalFields: ({commit, state, rootState}, additionalFields) => {
      const query = `
        mutation (
          $reportId: Int!,
          $lowLevelClouds: JSON,
          $midLevelClouds: JSON,
          $highLevelClouds: JSON,
          $precipitation: JSON,
          $meteorologicalPhenomena: JSON,
          $weatherNotes: String!,
          $instrumentOperationalNotes: String!,
        ) {
          createFieldCatalogReportAdditionalField(
            input: {
              fieldCatalogReportAdditionalField: {
                reportId: $reportId,
                lowLevelClouds: $lowLevelClouds,
                midLevelClouds: $midLevelClouds,
                highLevelClouds: $highLevelClouds,
                precipitation: $precipitation,
                meteorologicalPhenomena: $meteorologicalPhenomena,
                weatherNotes: $weatherNotes,
                instrumentOperationalNotes: $instrumentOperationalNotes,
              },
            }
          ) {
            fieldCatalogReportAdditionalField {
              reportId
            }
          }
        }
      `

      const variables = {
        reportId: additionalFields?.reportId,
        lowLevelClouds: additionalFields?.lowLevelClouds,
        midLevelClouds: additionalFields?.midLevelClouds,
        highLevelClouds: additionalFields?.highLevelClouds,
        precipitation: additionalFields?.precipitation,
        meteorologicalPhenomena: additionalFields?.meteorologicalPhenomena,
        weatherNotes: additionalFields?.weatherNotes,
        instrumentOperationalNotes: additionalFields?.instrumentOperationalNotes,
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query,
          variables,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    updateCatalogReport: ({commit, state, rootState}, catalogReport) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              updateFieldCatalogCatalogReportByReportId(
                input: {
                  reportId: ${catalogReport.reportId},
                  fieldCatalogCatalogReportPatch: {
                    planDetails: """${catalogReport.planDetails}""",
                    reportDate: """${catalogReport.reportDate}""",
                    shortDescription: """${catalogReport.shortDescription}""",
                  },
                }
              ) {
                fieldCatalogCatalogReport {
                  reportId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    updateCatalogAdditionalFields: ({commit, state, rootState}, additionalFields) => {
      const query = `
        mutation (
          $reportId: Int!,
          $lowLevelClouds: JSON,
          $midLevelClouds: JSON,
          $highLevelClouds: JSON,
          $precipitation: JSON,
          $meteorologicalPhenomena: JSON,
          $weatherNotes: String!,
          $instrumentOperationalNotes: String!,
        ) {
          updateFieldCatalogReportAdditionalFieldByReportId(
            input: {
              reportId: $reportId,
              fieldCatalogReportAdditionalFieldPatch: {
                lowLevelClouds: $lowLevelClouds,
                midLevelClouds: $midLevelClouds,
                highLevelClouds: $highLevelClouds,
                precipitation: $precipitation,
                meteorologicalPhenomena: $meteorologicalPhenomena,
                weatherNotes: $weatherNotes,
                instrumentOperationalNotes: $instrumentOperationalNotes,
              },
            }
          ) {
            fieldCatalogReportAdditionalField {
              reportId
            }
          }
        }
      `

      const variables = {
        reportId: additionalFields?.reportId,
        lowLevelClouds: additionalFields?.lowLevelClouds,
        midLevelClouds: additionalFields?.midLevelClouds,
        highLevelClouds: additionalFields?.highLevelClouds,
        precipitation: additionalFields?.precipitation,
        meteorologicalPhenomena: additionalFields?.meteorologicalPhenomena,
        weatherNotes: additionalFields?.weatherNotes,
        instrumentOperationalNotes: additionalFields?.instrumentOperationalNotes,
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query,
          variables,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    createCatalogAttachment: ({commit, state, rootState}, catalogAttachment) => {
      const formData = new FormData()
      formData.append("file", catalogAttachment.file)

      return axios({
        method: "post",
        url: `${Config.fieldCampaignDashboardFileApi.host}/upload`,
        data: formData,
        params: {
          reportId: catalogAttachment.reportId,
          personId: catalogAttachment.personId,
        },
        withCredentials: true,
      })
    },
    deleteCatalogAttachment: ({commit, state, rootState}, catalogAttachment) => {
      const reportId = catalogAttachment.reportId
      const fileName = catalogAttachment.attachment.file.name
      return axios({
        method: "delete",
        url: `${Config.fieldCampaignDashboardFileApi.host}/deleteFile/${reportId}/${fileName}`,
        withCredentials: true,
      })
    },
    createCatalogComment: ({commit, state, rootState}, catalogComment) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createFieldCatalogReportComment(
                input: {
                  fieldCatalogReportComment: {
                    reportId: ${catalogComment.reportId},
                    personId: ${catalogComment.personId},
                    commentDate: "${catalogComment.commentDate}",
                    commentText: "${catalogComment.commentText}",
                  },
                }
              ) {
                fieldCatalogReportComment {
                  commentId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    createCatalogEmail: ({commit, state, rootState}, catalogEmail) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createFieldCatalogReportEmail(
                input: {
                  fieldCatalogReportEmail: {
                    reportId: ${catalogEmail.reportId},
                    personId: ${catalogEmail.personId},
                  },
                }
              ) {
                fieldCatalogReportEmail {
                  emailId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    deleteCatalogEmail: ({commit, state, rootState}, emailId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              deleteCatalogEmailByEmailId(
                input: {
                  emailId: ${emailId},
                }
              ) {
                catalogEmail {
                  emailId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getAvailableReportDates: ({commit, state, rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogReports(
              orderBy: REPORT_ID_DESC,
              first: 1,
              filter: {
                catalogId: {
                  equalTo: ${payload.catalogId}
                },
                reportTypeId: {
                  equalTo: ${payload.reportTypeId}
                }
              }
            ) {
              nodes {
                reportDate
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
    },
    getReportTypes: ({rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogReportTypes(
              condition: {catalogId: ${payload}}
              orderBy: REPORT_TYPE_ID_ASC
            ) {
              edges {
                node {
                  fieldCatalogReportTypeByReportTypeId {
                    reportTypeId
                    reportTypeName
                    reportTypeDesc
                    fieldCatalogCatalogReportsByReportTypeId(
                      last: 1
                      condition: {catalogId: ${payload}}
                      orderBy: REPORT_DATE_ASC
                    ) {
                      nodes {
                        reportDate
                      }
                    }
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getReports: ({commit, state, rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogReportTypes(condition: {reportTypeName: "${payload.reportType}"}) {
              nodes {
                reportTypeDesc
                fieldCatalogCatalogReportsByReportTypeId(
                  condition: {catalogId: ${payload.catalogId}}
                  orderBy: ${payload.orderBy}
                  offset: ${(payload.pageNumber - 1) * payload.pageSize}
                  first: ${payload.pageSize}
                  filter: {
                    recordText: {
                      includesInsensitive: """${payload.filter}"""
                    }
                  }
                ) {
                  nodes {
                    reportId
                    reportDate
                    reportUpdated
                    shortDescription
                    planDetails
                    personByPersonId {
                      nameFirst
                      nameLast
                    }
                    fieldCatalogReportTypeByReportTypeId {
                      reportTypeDesc
                    }
                    fieldCatalogReportAdditionalFieldByReportId {
                      weatherNotes
                    }
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                  }
                  totalCount
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    getReport: ({commit, state, rootState}, reportId) => {
      const data = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogReports(condition: {reportId: ${reportId}}) {
              nodes {
                reportId
                fieldCatalogReportAttachmentsByReportId {
                  nodes {
                    attachmentId
                    attachmentName
                    attachmentPath
                    personId
                  }
                }
                fieldCatalogReportCommentsByReportId {
                  nodes {
                    commentId
                    commentText
                    personByPersonId {
                      personId
                      nameFirst
                      nameLast
                    }
                    commentDate
                  }
                }
                fieldCatalogReportEmailsByReportId {
                  nodes {
                    personByPersonId {
                      nameFirst
                      nameLast
                      email
                      personId
                    }
                    emailId
                  }
                }
                planDetails
                shortDescription
                reportDate
                reportUpdated
                personByPersonId {
                  nameFirst
                  nameLast
                  personId
                }
                fieldCatalogReportTypeByReportTypeId {
                  reportTypeId
                  reportTypeName
                  reportTypeDesc
                  reportSchema
                }
                fieldCatalogCatalogByCatalogId {
                  catalogCode
                }
                fieldCatalogReportAdditionalFieldByReportId {
                  lowLevelClouds
                  midLevelClouds
                  highLevelClouds
                  precipitation
                  meteorologicalPhenomena
                  weatherNotes
                  instrumentOperationalNotes
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      }
      return axios(data)
    },
    getRecentReportForCampaign: ({rootState}, catalogId) => {
      const data = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogReports(
              condition: {catalogId: ${catalogId}}
              first: 1
              orderBy: REPORT_DATE_DESC
            ) {
              edges {
                node {
                  reportDate
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      }
      return axios(data)
    },
    getCalendarReportsAndPlotsIdsByMonth: ({rootState}, payload) => {
      const data = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogReports(
              condition: {catalogId: ${payload?.catalogId}}
              filter: {reportDate: {greaterThanOrEqualTo: "${payload?.minimumDate}", lessThanOrEqualTo: "${payload?.maximumDate}"}}
              orderBy: REPORT_DATE_ASC
            ) {
              groupedAggregates(groupBy: REPORT_DATE_TRUNCATED_TO_DAY) {
                keys
              }
              edges {
                node {
                  reportId
                  reportDate
                  reportTypeId
                  fieldCatalogReportTypeByReportTypeId {
                    reportTypeDesc
                  }
                }
              }
            }
            allDqoPlots(
              filter: {
                siteCode: {
                  in: "${payload.siteCode}"
                },
                filenameDate: {
                  greaterThanOrEqualTo: "${payload.startDate}",
                  lessThanOrEqualTo: "${payload.endDate}"
                }
              },
              orderBy: FILENAME_DATE_ASC
            ) {
              groupedAggregates(groupBy: FILENAME_DATE) {
                keys
                distinctCount {
                  id
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      }
      return axios(data)
    },
    getCalendarReportsByDateAndReportType: ({rootState}, payload) => {
      const data = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allFieldCatalogCatalogReports(
              condition: {catalogId: ${payload?.catalogId}, reportTypeId: ${payload?.reportTypeId}},
              filter: {reportDate: {greaterThanOrEqualTo: "${payload?.minimumDate}", lessThanOrEqualTo: "${payload?.maximumDate}"}}
            )
            {
              edges {
                node {
                  reportId
                  shortDescription
                  planDetails
                  reportDate
                  reportUpdated
                  fieldCatalogReportTypeByReportTypeId {
                    reportTypeDesc
                    reportTypeId
                  }
                  personByPersonId {
                    nameFirst
                    nameLast
                  }
                  fieldCatalogReportAdditionalFieldByReportId {
                    weatherNotes
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      }
      return axios(data)
    },
    // TODO - improve this
    sendEmailNotifications: ({dispatch, state, rootState, getters}, payload) => {
      const reportId = payload.reportId
      dispatch("getReport", reportId).then(response => {
        const nodes = response?.data?.data?.allFieldCatalogCatalogReports?.nodes
        if (nodes && Array.isArray(nodes) && nodes.length > 0) {
          const report = nodes[0]
          const campaign = report?.fieldCatalogCatalogByCatalogId?.catalogCode.toUpperCase()
          const reportType = report?.fieldCatalogReportTypeByReportTypeId?.reportTypeDesc
          const submitter = `${report?.personByPersonId?.nameFirst} ${report?.personByPersonId?.nameLast}`
          const lastUpdatedBy = `${report?.reportUpdated?.nameFirst} ${report?.reportUpdated?.nameLast}`
          const submissionDate = moment.utc(report?.reportEntered?.datetime).format("YYYY-MM-DD HH:mm:ss")
          const lastUpdatedDate = moment.utc(report?.reportUpdated?.datetime).format("YYYY-MM-DD HH:mm:ss")
          const planDetails = report.planDetails
          const shortDescription = report.shortDescription

          const submitterPersonId = report?.reportEntered?.personId
          const updaterPersonId = report?.reportUpdated?.personId
          const additionalRecipientsPersonIds = payload.formattedReport.email.map(email => email.value)

          const recipientPersonIds = []
          if (submitterPersonId) recipientPersonIds.push(submitterPersonId)
          if (updaterPersonId && updaterPersonId !== submitterPersonId) recipientPersonIds.push(updaterPersonId)
          if (additionalRecipientsPersonIds && Array.isArray(additionalRecipientsPersonIds) && additionalRecipientsPersonIds.length > 0) {
            additionalRecipientsPersonIds.forEach(additionalRecipientPersonId => {
              if (!recipientPersonIds.includes(additionalRecipientPersonId)) {
                recipientPersonIds.push(additionalRecipientPersonId)
              }
            })
          }

          const reportUrl = `${Config.app.host}/#/${payload.campaignAcronym}/report/${reportId}`

          const attachments = report?.fieldCatalogReportAttachmentsByReportId?.nodes
          attachments.forEach(attachment => {
            const attachmentName = attachment.attachmentName.replace(/ /g, "_").replace(/#/g, "")
            const attachmentUrl = `${reportUrl}?attachment=${attachmentName}`
            attachment.attachmentPath = attachmentUrl
          })

          const subject = `ARM Field Campaign Dashboard: ${campaign} Report submitted`

          const title = "ARM Field Campaign Dashboard"

          let text = `Campaign: ${campaign}<br />`
          text += `Report Type: ${reportType}<br />`
          text += `Report ID: ${reportId}<br />`
          text += `Submitter: ${submitter}<br />`
          text += `Submission date: ${submissionDate}<br />`

          if (!moment.utc(submissionDate).isSame(moment.utc(lastUpdatedDate))) {
            text += `Last Updated By: ${lastUpdatedBy}<br />`
            text += `Last Updated Date: ${lastUpdatedDate}<br />`
          }

          text += `<br />Plan Details:<br />${planDetails}<br />`
          text += `<br />Short Description:<br />${shortDescription}<br />`

          if (attachments && Array.isArray(attachments) && attachments.length > 0) {
            text += "Attachments:<br />"
            attachments.forEach(attachment => {
              text += `* <a href="${attachment.attachmentPath}">${attachment.attachmentName}</a><br />`
            })
          }

          text += `<br />To view or edit the report, click <a href="${reportUrl}">here</a><br />`
          text += `<br />If you have any questions, please <a href="mailto:adc@arm.gov">contact us</a>`

          text += "<br /><br />*** Automated E-Mail - Please do not reply ***"

          const simpleEmailRequest = {
            recipientPersonIds,
            senderName: "ARM Field Campaigns",
            senderEmail: "noreply@ornl.gov",
            subject,
            title,
            text,
            recaptchaResponse: payload.recaptchaResponse,
          }

          return axios({
            method: "post",
            url: `${Config.notification.baseURL}/sendHtmlEmail`,
            data: simpleEmailRequest,
          })
        }
      })
    },
  },
}

export default reports
