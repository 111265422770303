<script>
import { mapGetters } from "vuex"
import Back from "@/components/Back"
import ChartParams from "@/components/ChartParams"

export default {
  components: {
    Back,
    ChartParams,
  },
  data: () => ({
    chart: {},
    parsedChartUrl: null,
    showParams: false,
  }),
  computed: {
    ...mapGetters("global", ["getCampaignAcronym"]),
    chartCode() {
      return this.$route.params?.chartCode
    },
    chartLabel() {
      return this.chart?.chartLabel
    },
    chartInputSchema() {
      return this.chart?.chartInputSchema
    },
    chartUrl() {
      return this.chart?.chartUrl
    },
  },
  mounted() {
    this.$store.dispatch("global/getChart", this.chartCode).then(response => {
      this.chart = response?.data?.data?.allFieldCatalogCatalogCharts?.edges?.[0]?.node
      if (!this.chart.chartInputSchema) {
        this.parsedChartUrl = this.chart.chartUrl
      }
    })
  },
  methods: {
    getChart(params) {
      if (this.chartUrl) {
        const url = new URL(this.chartUrl)
        params.forEach(param => url.searchParams.set(param.id, param.value))
        this.parsedChartUrl = url.toString()
      }
    },
    breadcrumbClick(event) {
      const currentRoute = this.$route?.path
      const breadcrumb = event?.srcElement?.hash?.replace(/\#/g, "")
      if (currentRoute === breadcrumb) {
        this.parsedChartUrl = null
      }
    },
  },
}
</script>

<template>
  <div>
    <Back class="back" />
    <b-container v-if="chartInputSchema">
      <h1 class="font-weight-bold">
        {{ chartLabel }}
      </h1>
      <ChartParams
        :chart-fields="chartInputSchema"
        @getChartParams="getChart"
      />
    </b-container>
    <iframe
      v-if="parsedChartUrl"
      :src="parsedChartUrl"
      width="100%"
      class="iFrame"
      referrerpolicy="strict-origin-when-cross-origin"
      frameBorder="0"
    />
  </div>
</template>

<style>
.iFrame {
  min-height: calc(100vh - 85px - 22px - 40px - 40px - 112px);
}
</style>
