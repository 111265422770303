<script>
import { mapGetters, mapActions } from "vuex"
import Spinner from "@/components/Spinner"
import ResultsContainer from "@/components/ResultsContainer"

export default {
  components: {
    Spinner,
    ResultsContainer,
  },
  data() {
    return {
      campaigns: [],
      campaignFields: [
        {
          key: "campaignName",
          label: "Campaign",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        {
          key: "action",
          label: "",
        },
      ],
    }
  },
  computed: {
    ...mapGetters("global", ["getCampaignAcronym"]),
  },
  mounted() {
    this.getCampaigns()
  },
  methods: {
    ...mapActions("global", [
      "setCampaign",
    ]),
    getCampaigns() {
      this.$store.dispatch("global/getCampaigns").then(response => {
        this.campaigns = response?.data?.data?.allFieldCatalogCatalogs?.nodes.map(node => {
          return {
            key: {
              catalogId: node.catalogId,
              campaignAcronym: node.fcAcronym,
              campaignSiteCode: node.siteCode,
              campaignCode: node.catalogCode,
            },
            campaignName: node.name + " (" + node.fcAcronym?.toUpperCase() + ")",
            proposedAcronym: node.fcAcronym,
            catalogId: node.catalogId,
            startDate: node.startDate,
            endDate: node.endDate,
            siteCode: node.siteCode,
            action: [
              {
                key: "goToCampaign",
                label: "Go to campaign",
              },
            ],
          }
        })
      })
    },
    goToCampaign(val) {
      this.setCampaign(val)
      this.$router.push({
        path: `/${this.getCampaignAcronym}/overview`,
      })
    },
  },
}
</script>

<template>
  <b-container class="py-3">
    <h1 class="font-weight-bold">
      ARM Field Campaign Dashboard
    </h1>
    <ResultsContainer
      v-if="Array.isArray(campaigns) && campaigns.length > 0"
      :rows="campaigns"
      :fields="campaignFields"
      hide-totals
      @goToCampaign="goToCampaign($event)"
    />
    <Spinner
      v-else
      text="Loading campaigns..."
    />
  </b-container>
</template>

<style scoped>
</style>
