<script>
import { mapGetters } from "vuex"
import ResultsContainer from "@/components/ResultsContainer"
import Spinner from "@/components/Spinner"

export default {
  components: {
    ResultsContainer,
    Spinner,
  },
  data: () => ({
    loading: false,
    selectedChart: "",
    chartOptions: [],
    chartFields: [
      { key: "chart", label: "Chart" },
      { key: "action", label: "Action" },
    ],
  }),
  computed: {
    ...mapGetters("global", ["getCatalogId", "getCampaignAcronym"]),
  },
  mounted() {
    this.getCharts()
  },
  methods: {
    getCharts() {
      this.loading = true
      this.$store.dispatch("global/getCharts", this.getCatalogId).then(response => {
        this.chartOptions = response?.data?.data?.allFieldCatalogCatalogCharts?.edges?.map(edge => {
          return {
            key: edge?.node?.chartCode,
            chart: edge?.node?.chartLabel,
            action: [
              {
                key: "viewChart",
                label: "View Chart",
              },
            ],
          }
        })
        this.loading = false
      }).catch(error => {
        console.error(error)
        this.loading = false
      })
    },
    viewChart(chartCode) {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/chart/${chartCode}`,
      })
    },
  },
}
</script>

<template>
  <b-container class="py-3">
    <h1 class="font-weight-bold">
      {{ getCampaignAcronym.toUpperCase() }} Charts
    </h1>

    <Spinner
      v-if="loading"
      text="Loading charts..."
    />

    <ResultsContainer
      v-else-if="chartOptions.length > 0"
      :rows="chartOptions"
      :fields="chartFields"
      hide-totals
      @viewChart="viewChart"
    />

    <b-card
      v-else
      class="primary-background-light border-0 text-center mt-2"
    >
      <b-card-title class="no-results">
        No charts found.
      </b-card-title>
    </b-card>
  </b-container>
</template>

<style>
</style>
